@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "TT Norms Pro";
  src: url("/theme/fonts/TTNormsPro-Medium.woff2") format("woff2"),
    url("/theme/fonts/TTNormsPro-Medium.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: bold;
  font-family: "TT Norms Pro";
  src: url("/theme/fonts/TTNormsPro-Bold.woff2") format("woff2"),
    url("/theme/fonts/TTNormsPro-Bold.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: "TT Norms Pro";
  src: url("/theme/fonts/TTNormsPro-Regular.woff2") format("woff2"),
    url("/theme/fonts/TTNormsPro-Regular.woff") format("woff");
  font-display: fallback;
}
