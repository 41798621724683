.ox-setting-menu {
  padding: 90px 30px 0;
  position: relative;
  width: 100%;
}

.ox-setting-menu__option {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: #fff;

  &-item {
    width: 100%;
    margin-bottom: 30px;

    .ox-setting-menu__desc,
    .ox-setting-menu__bar-frame {
      display: flex;
      align-items: center;
      height: 20px;

      .ox-setting-menu__bar {
        margin-left: 0;
      }
    }
  }

  &:last-of-type {
    .ox-setting-menu__option-item {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 1.2rem;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    color: #fff;
  }

  @include more-than(md) {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-right: 0;
  }
}

.ox-setting-menu__text {
  flex: 0 0 8.5rem;
  max-width: 8.5rem;

  @include more-than(sm) {
    flex: 0 0 17rem;
    max-width: 17rem;
  }

  @include more-than(md) {
    flex: auto;
    max-width: 100%;
  }
}

.ox-setting-menu__bar {
  position: relative;
  width: 100%;
  height: 1px;
  margin-left: 2rem;
  background-color: #777777;
  margin-left: 0;
}

.ox-setting-menu__control {
  position: absolute;
  top: -1.7rem;
  left: 3%;
  display: block;
  width: 3.4rem;
  height: 3.4rem;
  padding: 1rem;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 1.4rem;
    height: 1.4rem;
    border: 1px solid #000;
    border-radius: 50%;
    background-color: #fff;
  }
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

.ox-range-dot {
  position: absolute;
  top: -4px;
  width: 100%;
}
