* {
  font-family: 'neue-haas-grotesk-display', sans-serif;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

.hidden {
  display: none;
}

.mt-150 {
  margin-top: 150px;
}

p {
  font-size: 1.6rem;
}

p a,
h6 a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: none;
}

p a:hover,
h6 a:hover {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: underline;
}

.filter-row {
  .categoryBtn {
    background-color: transparent;
    padding: 10px 25px;
    border: none;
    outline: none;
    color: #757575;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
    padding: 10px 25px;

    &:disabled {
      color: #c7c6c6;
    }

    &:hover, &:focus, &:active {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  
    &.activeCategory {
      text-decoration: underline !important;
    }
  }
}

.web-link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #000;
  bottom: 0;
  right: 0;
  z-index: 9997;
}

.web-link i {
  font-size: 30px;
  color: #fff;
  transition: color 0.3s;
}

.web-link:hover {
  text-decoration: none;
}

.web-link:hover i {
  color: #1c8dba;
}

#limits {
  .container {
    .limits-header {
      font-size: 4rem;
      font-weight: 700;
      color: black;
      padding: 50px 0px 50px;
    }

    .filter-row {
      padding-bottom: 50px;
    }
  }
}

#nav-custom {
  padding-right: 30px;
  padding-left: 30px;
}

footer {
  padding: 0 3rem;
}

footer .row:not(.social-row) {
  padding: 20px 0;
}

.custom-container {
  padding: 0;
  max-width: 2200px !important;
  margin: 0 auto;
  width: 100%;
}

.custom-container .portfolio-item {
  padding-right: 15px;
  padding-left: 15px;
}

#portfolio {
  .container {
    .reference-card {
      display: flex;
      flex-direction: column;
      padding: 15px 0 90px;

      .text {
        padding: 25px 0px 0;

        .type {
          color: grey;
          font-size: 1rem;
          font-weight: 600;
        }

        .category-tag {
          color: #fff;
          font-size: 0.7rem;
          font-weight: 600;
          padding: 3px 10px;
          letter-spacing: 0.5px;
          margin-right: 5px;
          margin-bottom: 10px;
          background-color: #cac9c9;
          border-radius: 5px;
          display: inline-block;
        }

        .category-tag:last-child {
          margin-right: 0;
        }

        .name {
          color: black;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: 0.5px;
        }

        p {
          font-weight: 400;
          font-size: 1rem;
          color: #9c9c9c;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .custom-container .portfolio-item {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }

  #nav-custom {
    padding-right: 6.4vw;
    padding-left: 6.4vw;
  }
}


@media (min-width: 992px) {
  .custom-container .portfolio-item {
    padding-right: 6.4vw;
    padding-left: 6.4vw;
  }

  #nav-custom {
    padding-right: 12.8vw;
    padding-left: 12.8vw;
  }
}

@media (min-width: 1280px) {
  .custom-container .portfolio-item {
    padding-right: 4.8vw;
    padding-left: 4.8vw;
  }

  #nav-custom {
    padding-right: 9.6vw;
    padding-left: 9.6vw;
  }
}

@media (min-width: 2200px) {
  .custom-container .portfolio-item {
    padding-right: 66px;
    padding-left: 66px;
  }

  #nav-custom {
    padding-right: 132px;
    padding-left: 132px;
  }
}



@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

/**
.card-small, .card-big {

  transition: transform 0.25s ease-in-out;
  -webkit-transition: transform 0.25s ease-in-out;

  .img, img  {
    transform: scale(1);
    transition: transform 0.25s ease-in-out;
    -webkit-transition: transform 0.25s ease-in-out;
    overflow: hidden;
  }

  .text {
    transform: translate(0px, 0px);
    transition: transform 0.25s ease-in-out;
    -webkit-transition: transform 0.25s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    .img {
      transform: scale(0.95);
      overflow: hidden;
      transition: transform 0.25s ease-in-out;
      -webkit-transition: transform 0.25s ease-in-out;
    }

    img {
      transform: scale(1.1);
      transition: transform 0.25s ease-in-out;
      -webkit-transition: transform 0.25s ease-in-out;
    }

    .text {
      transform: translate(40px, 0px);
      transition: transform 0.25s ease-in-out;
      -webkit-transition: transform 0.25s ease-in-out;
    }
  }
}
*/
.gallery-item-hover{
  .gallery_preview_item_text{
    position:absolute; 
    bottom: 0px; 
    left: 0px;
    padding: 20px;
    color: black;
    text-align: left;
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.5s;
    p{
      margin: 0px;
    }
    .gallery_preview_item_title{
      font-weight: 500;
      font-size: 20px;
    }
    .gallery_preview_item_subtitle{
      font-size: 18px;
    }
  }
  &:hover{
    .gallery_preview_item_text{
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

