a {
  transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
}

.navbar-brand {
  text-decoration: none;
}

.ox-header__logo {

    a {

      span {
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        font-weight: 600;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: black;
        }
      }
    }

    .foreground {
      font-family: 'canada-type-gibson', sans-serif;
      font-size: 2.4rem;
    }

    .fa-bars {
      font-size: 3.2rem;
    }

}
.side-menu-container{
  padding: 90px 30px 0;
  position: fixed; 
  width: 600px; 
  max-width: 100%; 
  height: 100vh; 
  background-color: rgba(0, 0, 0, 0.92);
  top:0px; 
  right: 0px;
  transform: translateX(100%);
  transition: all .3s;
  &.show{
    transform: translateX(0%);
  }
  nav{
    ul{
      list-style: none;
      li{
        a{
          position: relative;
          display: inline-block;
          padding: 0.5rem 0;
          margin: 0;
          font-size: 1.2rem;
          letter-spacing: 0.25rem;
          text-transform: uppercase;
          line-height: 1.6;
          font-weight: 400;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
