.ox-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  z-index: 99999;
}

.ox-u-menu-open .ox-header {
  @include more-than(lg) {
    padding: 2rem 4.4rem 2rem 3rem;
  }
}

.ox-header--sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
}

.ox-header__logo {
  position: relative;
  z-index: 2;
  display: block;
}

.ox-header__logo svg {
  opacity: 1;
  width: 10rem;
  transition: opacity 0.2s ease-in-out;

  @include more-than(sm) {
    width: 13.3rem;
  }
}

.ox-header__logo:hover > svg {
  opacity: 0;
}

.ox-header__logo-hover-el {
  position: absolute;
  top: 4px;
  left: 3px;
  display: flex;
  opacity: 0;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  transition: opacity 0.2s ease-in-out;
  background: #fff;

  svg {
    width: 15rem;
    position: relative;
    top: -2px;
    left: -13px;

    @include less-than(sm) {
        width: 11.6rem;
        top: -3px;
    }

  }
}

.ox-header__logo:hover .ox-header__logo-hover-el {
  opacity: 1;
}

.ox-header__logo-text {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #7f7f7f;
  letter-spacing: 0.25rem;

  @include more-than(sm) {
    font-size: 1.2rem;
  }
}

.ox-header__logo-trans {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-left: 1rem;
  font-weight: $ox-fw-bold;
  font-size: 1.4rem;
  color: #000;
  letter-spacing: -0.051rem;

  @include more-than(sm) {
    padding: 0 2rem;
    margin-left: 1.7rem;
    font-size: 1.8rem;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: -12px;
    font-weight: $ox-fw-medium;
    font-size: 2.6rem;

    @include more-than(sm) {
      top: -18px;
      font-size: 3.6rem;
    }
  }

  &::before {
    content: "[";
    left: 0;
  }

  &::after {
    content: "]";
    right: 0;
  }
}

.ox-header__navs {
  display: flex;
  align-items: center;
}

.ox-header__nav {
  margin: 0 0.5rem;

  @include more-than(sm) {
    margin: 0 1.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
}

.ox-header__nav-btn {
  padding: 1rem;
  border: 0;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.5;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.ox-header__nav-btn--disabled {
  opacity: 0.5;
}

.ox-header__dropwindow {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  z-index: 10;
  height: 100vh;
  overflow: auto;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.92);
  will-change: top;
  transform: translateX(100%);
  transition: transform 0.4s linear;
  padding-top: 0;

  @include more-than(md) {
    height: auto;
    max-height: 100vh;
  }
}

.ox-header__dropwindow--in-anim {
  transform: translateX(0);

  @include more-than(md) {
    transform: translateX(100%);
    animation: dropAnim 0.4s forwards;
    animation-play-state: running;
    animation-timing-function: ease-out;
  }
}

.ox-header__dropwindow--active {
  transform: translateX(0);
}

.ox-header__dropwindow--small {
  height: auto;
}

.ox-header__close-btn {
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 30px;
}

@keyframes dropAnim {
  80% {
    transform: translateX(5%);
  }

  100% {
    transform: translateX(0%);
  }
}

.ox-header__nav--user {
  display: none;
}
