body{
  overflow-x: hidden;
}
#_cms-user-info, ._cms_toolbar-nav {
  img, svg {
    width: unset !important;
    display: unset !important;
  }
}
.container{
  margin: 0px auto;
  width: 100%;
}
