.ox-toogler {
  padding: 1rem 0 1rem 1rem;
  border: 0;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.5;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.ox-toggler__bar {
  display: block;
  width: 2.6rem;
  height: 1px;
  margin-bottom: 0.7rem;
  background-color: #000;

  &:last-child {
    margin-bottom: 0;
  }
}
