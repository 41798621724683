label {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
}

.form-input-row {
    margin-bottom: 15px;
}

.form-btn {
    display: inline-block;
    color: #fff;
    background: #000;
    background-color: #000;
    border: 1px solid #000;
    border-color: #000;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    padding: 15px 30px;
    outline: none;
    box-shadow: none;
    margin: 0;
    cursor: pointer;
    border-radius: 0;
    transition: color 0.3s, background-color 0.3s;
}

.form-btn:hover {
    color: #000;
    background: #fff;
    background-color: #fff;
}

.file-input-group #fileid.detail-file_input {
    display: none;
}

input.form-control, textarea.form-control {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    min-height: 42px;
    outline: none;
    box-shadow: none;
}

input.form-control:focus, textarea.form-control:focus {
    border: 1px solid #000;
    outline: none;
    box-shadow: none;
}

input[type="checkbox"] {
    top: 1px;
    position: relative;
    width: 16px;
    height: 16px;
    outline: none;
}

input[type="checkbox"]:hover,
input[type="checkbox"]:focus {
    outline: none;
}

input[type="checkbox"]:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border: 1px solid #000;
}

input[type="checkbox"]:after {
    content: "";
    position: absolute;
    display: block;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid #000;
    opacity: 0;
    transition: opacity 0.3s;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input[type="checkbox"]:checked:after {
    opacity: 1;
}