.ox-u-global-overflow-hidden {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background: white;

  @media screen and (min-height: 750px) {
    margin-bottom: 79.5rem; /* footer height */
  }
}

.ox-u-menu-open {
  overflow: hidden;
}

.ox-u-sticky-heder {
  padding-top: 6.6rem;

  @include more-than(sm) {
    padding-top: 8.6rem;
  }
}

.ox-u-fw-bold {
  font-weight: $ox-fw-bold;
}

.ox-u-fc-black {
  color: black;
}

.ox-u-blog-margin {
  margin: 3rem 0;

  @include more-than(sm) {
    margin: 4rem 0;
  }

  @include more-than(lg) {
    margin: 7rem 0;
  }
}
