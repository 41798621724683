.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 0;
  background: #fff;
  transition: max-height 4s, opacity 3s;
  opacity: 0;
  z-index: 99998;
  overflow: hidden;
}
.overlay.overlay-active {
  opacity: 1;
  max-height: 9999px;
  transition: max-height 0.5s, opacity 0.5s;
}
.overlay .title-part {
  margin-top: 25%;
  height: 100%;
  max-height: 0;
  background: #fff;
  overflow: hidden;
  transition: max-height 15s;
}
.overlay.overlay-active .title-part {
  max-height: 9999px;
  transition: max-height 30s;
}