/*

No CSS declaration, this file is only for .scss imports!
You can see folder structure below.

|â base/
|   |â _reset.scss       # Reset/normalize
|   |â _typography.scss  # Typography rules
|   ...                  # Etcâ¦
|
|â components/
|   |â _buttons.scss     # Buttons
|   |â _carousel.scss    # Carousel
|   |â _cover.scss       # Cover
|   |â _dropdown.scss    # Dropdown
|   ...                  # Etcâ¦
|
|â layout/
|   |â _navigation.scss  # Navigation
|   |â _grid.scss        # Grid system
|   |â _header.scss      # Header
|   |â _footer.scss      # Footer
|   |â _sidebar.scss     # Sidebar
|   |â _forms.scss       # Forms
|   ...                  # Etcâ¦
|
|â pages/
|   |â _home.scss        # Home specific styles
|   |â _contact.scss     # Contact specific styles
|   ...                  # Etcâ¦
|
|â themes/
|   |â _theme.scss       # Default theme
|   |â _admin.scss       # Admin theme
|   ...                  # Etcâ¦
|
|â abstract/ 
|   |â _variables.scss   # Sass Variables
|   |â _functions.scss   # Sass Functions
|   |â _mixins.scss      # Sass Mixins
|   |â _helpers.scss     # Class & placeholders helpers
|
|â vendors/
|   |â _bootstrap.scss   # Bootstrap 
|   |â _jquery-ui.scss   # jQuery UI
|   ...                  # Etcâ¦ 

*/


// Bootstrap imports
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'abstract/b-variables';

@import "abstract/_mixins.scss";
@import "abstract/_fonts.scss";
@import "abstract/_variables.scss";
@import "abstract/_utilities.scss";

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/grid';

@import '~bootstrap/scss/utilities';
@import "~tailwindcss/base";

@import "~tailwindcss/components";

@import "~tailwindcss/utilities";
@import 'abstract/mixins';

@import 'base/base.scss';

// Pages
@import 'pages/references';
@import 'pages/sluzby';


// Layout
@import 'layout/index';
@import 'layout/_main-detail.scss';


// Components
@import 'components/_header.scss';
@import 'components/_grid.scss';
@import 'components/_setting-menu.scss';
@import 'components/_search-menu.scss';
@import 'components/_filter-text.scss';
@import 'components/_forms.scss';
@import 'components/_close-btn.scss';
@import 'components/_toggler.scss';
@import 'components/menu-primary';
@import 'components/detail-modal.scss';
@import 'components/detail-img.scss';
@import 'components/overlay.scss';
@import 'components/custom_grid_xxl';
@import 'components/footer.scss';




