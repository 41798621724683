.ox-close-btn {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: -2px;
    width: calc(100% + 0.5rem);
    height: 2px;
    background-color: #fff;
    transform-origin: center;

    @include more-than(sm) {
      height: 1px;
    }
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.ox-close-btn--dark {
  &::after,
  &::before {
    background-color: #000;
  }
}

.ox-close-btn--sm {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;

  @include more-than(sm) {
    width: 1.5rem;
    height: 1.5rem;
  }


  &::after,
  &::before {
    top: 7px;
  }
}

.ox-close-btn--no-hover {
  &:hover {
    opacity: 1;
  }
}
