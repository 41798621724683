#sluzby{
    .sluzby-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .sluzby-left-side{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 50vw;
        min-height: 100vh;
        background-color: rgb(21, 22, 29);

        display: flex;
        align-items: center;

        nav{
            padding-left: 92px;
            ul{
                list-style: none;
                padding: 0px;
            }
            li{
                color: white;
                margin-bottom: 20px;
                transition: all .3s;
            }
            li:hover{
                transform: translateX(20px);
            }
            span{ font-size: 16px;}
            a{
                font-size: 122px;
                line-height: 122px;
                color: inherit;
                text-decoration: none;
                padding-left: 20px;
            }
        }
    }


    .sluzby-right-side{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50vw;
        section{
            min-height: 100vh;
            width: 100%;
            padding: 92px;
        }
        #branding{
            background-color: rgb(218, 218, 218);
        }
        #print{
            background-color: white;
        }
        #weby{
            background-color: rgb(218, 218, 218);
        }
    }
}