.detail-modal {
    z-index: 10000;
    padding-right: 0 !important;
    .modal-dialog {
        width: 100%;
        max-width: unset;
        height: 100%;
        margin: 0;
        .modal-content {
            height: auto;
            min-height: 100%;
            border: none;
            border-radius: 0;
            background: #fff;
            background-color: #fff;
            .modal-header {
                padding: 15px;
                border: none;
                button.close-btn {
                    z-index: 10001;
                    position: fixed;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: auto;
                    right: 0;
                    margin: 0 3vw 0;                    
                    font-size: 21px;
                    color: #000;
                    background: transparent;
                    width: 40px;
                    border: 1px solid #000;
                    height: 40px;
                    line-height: 21px;
                    text-align: center;
                    -webkit-border-radius: 50%;
                    -khtml-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    border-radius: 50%;
                    padding-top: 1px;
                    opacity: 0.9;
                    transition: all .3s linear 0s;
                }
                button.close-btn:hover {
                    transform: scale(1.1);
                    opacity: 1;
                    color: #000;
                    border-color: #000;
                }
                button.close-btn:focus {
                    outline: none;
                }
            }
            .modal-body {
                padding: 15px;
                #popup-bebeautiful {
                    .popup-content {
                        padding-top: 45px;
                        padding-bottom: 40px;
                        h3, p {
                            font-family: 'Montserrat';
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}