.ox-detail-img {
  position: relative;
  min-height: calc(100vh - 6.6rem);
  padding: 0 1rem 6.6rem 1rem;
  text-align: center;

  @include more-than(sm) {
    min-height: calc(100vh - 8.6rem);
    padding: 0 1rem 8.6rem 1rem;
  }

  .swiper {
    .swiper-pagination {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background: #000;
          background-color: #000;
          color: #000;
        }
      }
    }
  }
}

.ox-detail-img__img-el {
  width: auto;
  max-width: 100%;
  max-height: calc(100vh - 6.6rem - 6.6rem);

  @include more-than(sm) {
    max-height: calc(100vh - 8.6rem - 8.6rem);
  }
}

.ox-detail-img__title {
  margin-bottom: 3rem;
  font-weight: $ox-fw-bold;
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0.007rem;
  text-align: center;

  @include more-than(xl) {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  &.ox-detail-img__advertisement {
    font-size: 3rem;

    @media (min-width: 992px) {
      font-size: 3.2rem;
    }

    @media (min-width: 1200px) {
      font-size: 3.6rem;
    }

    @media (min-width: 1300px) {
      font-size: 4rem;
    }

    @media (min-width: 1400px) {
      font-size: 4.2rem;
    }
  }
}

.ox-detail-img__text {
  margin: 0;
  font-weight: $ox-fw-regular;
  font-size: 1.4rem;
  line-height: 2.3rem;
  text-align: center;
  color: $ox-c-grey-alternate;
  letter-spacing: 0.1rem;
  // letter-spacing: 0.015rem;

  @include more-than(xl) {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  p, strong, a, ul, li {
    font-weight: $ox-fw-regular;
    font-size: 1.4rem;
    line-height: 2.3rem;
    text-align: center;
    color: $ox-c-grey-alternate;
    letter-spacing: 0.1rem;
  }

  &.ox-detail-img__advertisement {
    color: #212529;
    text-align: left;
    font-size: 1.6rem;

    strong {
      color: #212529;
      text-align: left;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    p, a, ul, li, li strong {
      color: #212529;
      text-align: left;
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
  }

  ul {
    padding-left: 18px;
  }

  strong {
    font-weight: 600;
  }

  a.active-link {
    font-weight: 600;
  }
}
