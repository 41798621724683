
#filterText {
  position: relative;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.ox-filter-text {
  font-weight: $ox-fw-regular;
  font-size: 2.1rem;
  line-height: 2.7rem;
  padding: 2rem 3rem;
  width: 79%;
  margin-bottom: 3rem;

  @include more-than(sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-bottom: 0;
    padding: 0 0 3.6rem;
  }

  @include more-than(md) {
    font-size: 1.9rem;
  }

  @include more-than(xl) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .d-flex {
    @include less-than(sm) {
      display: inline-block !important;
    }
  }
}

.ox-filter-text__text {
  display: inline;
  @include more-than(sm) {
    padding-right: 1.5rem
  }
}

.ox-filter-text__item {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: $ox-fw-bold;
  cursor: pointer;

  & span {
    position: relative;
    display: inline-block;

    &.normal-text {
      font-weight: $ox-fw-regular;
    }
  }

  & span:not(.normal-text)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000000;
    transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
  }

  &:hover span:not(.normal-text)::after {
    bottom: 100%;
    opacity: 0;
  }
}

.ox-filter-text__close {
  position: absolute;
  top: -0.8rem;
  left: 19rem;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  @include more-than(sm) {
    position: relative;
    top: 1px;
    display: inline-flex;
    align-items: center;
    margin-left: .8rem;
    left: initial;
    vertical-align: middle;
  }
}
