body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
}

#galleryApp {
  width: 100%;
  flex-grow: 1;
}

.dark {
 background-color: #171717 !important;
}

.light {
  background-color: #eeeeee !important;
}
