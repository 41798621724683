.navbar .container-xxl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.navbar .container-xxl .menu-logos {
  display: flex;
}

.navbar .container-xxl .menu-logos img {
  padding-left: 25px;
}

.navbar .container-xxl .logo-text {
  color: #0D141C;
  font-size: 32px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 0.9;
}

.navbar .container-xxl .navbar-collapse-x {
  -ms-flex-preferred-size: 100%;
  /* flex-basis: 100%; */
  -ms-flex-positive: 1;
  /* flex-grow: 1; */
  align-items: center;
}

.navbar .container-xxl .navbar-nav {
  flex-direction: row !important;
}

.navbar .container-xxl .collapse:not(.show) {
  display: unset;
}

.navbar .container-xxl #navbar-menu ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.navbar .container-xxl #navbar-menu ul li {
  padding: 0 20px;
  font-size: 16px;
  color: black;
}

.navbar .container-xxl #navbar-menu ul li a {
  color: black;
  padding: 0px 5px 2px 5px;
}

.navbar .container-xxl #navbar-menu ul li a:hover {
  text-decoration: none;
  color: black;
}

.navbar .container-xxl .header-text {
  margin-right: auto;
}

.navbar .container-xxl .header-text span {
  font-family: verveine, sans-serif;
  font-size: 31px;
  color: #b1b1b1;
  line-height: 1;
}

 .container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
 }

.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

 @media (min-width: 1280px) {
   .container-xl, .container-lg, .container-md, .container-sm, .container {
     max-width: 1140px;
   }
 }

 @media (min-width: 1440px) {
   .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
     max-width: 1320px;
   }
 }

 @media (min-width: 1440px) {
   .col-xxl {
     flex: 1 0 0%;
   }
 
   .row-cols-xxl-auto > * {
     flex: 0 0 auto;
     width: auto;
   }
 
   .row-cols-xxl-1 > * {
     flex: 0 0 auto;
     width: 100%;
   }
 
   .row-cols-xxl-2 > * {
     flex: 0 0 auto;
     width: 50%;
   }
 
   .row-cols-xxl-3 > * {
     flex: 0 0 auto;
     width: 33.3333333333%;
   }
 
   .row-cols-xxl-4 > * {
     flex: 0 0 auto;
     width: 25%;
   }
 
   .row-cols-xxl-5 > * {
     flex: 0 0 auto;
     width: 20%;
   }
 
   .row-cols-xxl-6 > * {
     flex: 0 0 auto;
     width: 16.6666666667%;
   }
 
   .col-xxl-auto {
     flex: 0 0 auto;
     width: auto;
   }
 
   .col-xxl-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }
 
   .col-xxl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 
   .col-xxl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }
 
   .col-xxl-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }
 
   .col-xxl-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }
 
   .col-xxl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }
 
   .col-xxl-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }
 
   .col-xxl-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }
 
   .col-xxl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }
 
   .col-xxl-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }
 
   .col-xxl-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }
 
   .col-xxl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 
   .offset-xxl-0 {
     margin-left: 0;
   }
 
   .offset-xxl-1 {
     margin-left: 8.3333333333%;
   }
 
   .offset-xxl-2 {
     margin-left: 16.6666666667%;
   }
 
   .offset-xxl-3 {
     margin-left: 25%;
   }
 
   .offset-xxl-4 {
     margin-left: 33.3333333333%;
   }
 
   .offset-xxl-5 {
     margin-left: 41.6666666667%;
   }
 
   .offset-xxl-6 {
     margin-left: 50%;
   }
 
   .offset-xxl-7 {
     margin-left: 58.3333333333%;
   }
 
   .offset-xxl-8 {
     margin-left: 66.6666666667%;
   }
 
   .offset-xxl-9 {
     margin-left: 75%;
   }
 
   .offset-xxl-10 {
     margin-left: 83.3333333333%;
   }
 
   .offset-xxl-11 {
     margin-left: 91.6666666667%;
   }
 
   .g-xxl-0,
 .gx-xxl-0 {
     --bs-gutter-x: 0;
   }
 
   .g-xxl-0,
 .gy-xxl-0 {
     --bs-gutter-y: 0;
   }
 
   .g-xxl-1,
 .gx-xxl-1 {
     --bs-gutter-x: 0.25rem;
   }
 
   .g-xxl-1,
 .gy-xxl-1 {
     --bs-gutter-y: 0.25rem;
   }
 
   .g-xxl-2,
 .gx-xxl-2 {
     --bs-gutter-x: 0.5rem;
   }
 
   .g-xxl-2,
 .gy-xxl-2 {
     --bs-gutter-y: 0.5rem;
   }
 
   .g-xxl-3,
 .gx-xxl-3 {
     --bs-gutter-x: 1rem;
   }
 
   .g-xxl-3,
 .gy-xxl-3 {
     --bs-gutter-y: 1rem;
   }
 
   .g-xxl-4,
 .gx-xxl-4 {
     --bs-gutter-x: 1.5rem;
   }
 
   .g-xxl-4,
 .gy-xxl-4 {
     --bs-gutter-y: 1.5rem;
   }
 
   .g-xxl-5,
 .gx-xxl-5 {
     --bs-gutter-x: 3rem;
   }
 
   .g-xxl-5,
 .gy-xxl-5 {
     --bs-gutter-y: 3rem;
   }
 }

 //-------------------------------------------------------------------------------------------------------------------------

 @media (min-width: 1440px) {
   .d-xxl-inline {
     display: inline !important;
   }
 
   .d-xxl-inline-block {
     display: inline-block !important;
   }
 
   .d-xxl-block {
     display: block !important;
   }
 
   .d-xxl-grid {
     display: grid !important;
   }
 
   .d-xxl-table {
     display: table !important;
   }
 
   .d-xxl-table-row {
     display: table-row !important;
   }
 
   .d-xxl-table-cell {
     display: table-cell !important;
   }
 
   .d-xxl-flex {
     display: flex !important;
   }
 
   .d-xxl-inline-flex {
     display: inline-flex !important;
   }
 
   .d-xxl-none {
     display: none !important;
   }
 
   .flex-xxl-fill {
     flex: 1 1 auto !important;
   }
 
   .flex-xxl-row {
     flex-direction: row !important;
   }
 
   .flex-xxl-column {
     flex-direction: column !important;
   }
 
   .flex-xxl-row-reverse {
     flex-direction: row-reverse !important;
   }
 
   .flex-xxl-column-reverse {
     flex-direction: column-reverse !important;
   }
 
   .flex-xxl-grow-0 {
     flex-grow: 0 !important;
   }
 
   .flex-xxl-grow-1 {
     flex-grow: 1 !important;
   }
 
   .flex-xxl-shrink-0 {
     flex-shrink: 0 !important;
   }
 
   .flex-xxl-shrink-1 {
     flex-shrink: 1 !important;
   }
 
   .flex-xxl-wrap {
     flex-wrap: wrap !important;
   }
 
   .flex-xxl-nowrap {
     flex-wrap: nowrap !important;
   }
 
   .flex-xxl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
 
   .justify-content-xxl-start {
     justify-content: flex-start !important;
   }
 
   .justify-content-xxl-end {
     justify-content: flex-end !important;
   }
 
   .justify-content-xxl-center {
     justify-content: center !important;
   }
 
   .justify-content-xxl-between {
     justify-content: space-between !important;
   }
 
   .justify-content-xxl-around {
     justify-content: space-around !important;
   }
 
   .justify-content-xxl-evenly {
     justify-content: space-evenly !important;
   }
 
   .align-items-xxl-start {
     align-items: flex-start !important;
   }
 
   .align-items-xxl-end {
     align-items: flex-end !important;
   }
 
   .align-items-xxl-center {
     align-items: center !important;
   }
 
   .align-items-xxl-baseline {
     align-items: baseline !important;
   }
 
   .align-items-xxl-stretch {
     align-items: stretch !important;
   }
 
   .align-content-xxl-start {
     align-content: flex-start !important;
   }
 
   .align-content-xxl-end {
     align-content: flex-end !important;
   }
 
   .align-content-xxl-center {
     align-content: center !important;
   }
 
   .align-content-xxl-between {
     align-content: space-between !important;
   }
 
   .align-content-xxl-around {
     align-content: space-around !important;
   }
 
   .align-content-xxl-stretch {
     align-content: stretch !important;
   }
 
   .align-self-xxl-auto {
     align-self: auto !important;
   }
 
   .align-self-xxl-start {
     align-self: flex-start !important;
   }
 
   .align-self-xxl-end {
     align-self: flex-end !important;
   }
 
   .align-self-xxl-center {
     align-self: center !important;
   }
 
   .align-self-xxl-baseline {
     align-self: baseline !important;
   }
 
   .align-self-xxl-stretch {
     align-self: stretch !important;
   }
 
   .order-xxl-first {
     order: -1 !important;
   }
 
   .order-xxl-0 {
     order: 0 !important;
   }
 
   .order-xxl-1 {
     order: 1 !important;
   }
 
   .order-xxl-2 {
     order: 2 !important;
   }
 
   .order-xxl-3 {
     order: 3 !important;
   }
 
   .order-xxl-4 {
     order: 4 !important;
   }
 
   .order-xxl-5 {
     order: 5 !important;
   }
 
   .order-xxl-last {
     order: 6 !important;
   }
 
   .m-xxl-0 {
     margin: 0 !important;
   }
 
   .m-xxl-1 {
     margin: 0.25rem !important;
   }
 
   .m-xxl-2 {
     margin: 0.5rem !important;
   }
 
   .m-xxl-3 {
     margin: 1rem !important;
   }
 
   .m-xxl-4 {
     margin: 1.5rem !important;
   }
 
   .m-xxl-5 {
     margin: 3rem !important;
   }
 
   .m-xxl-auto {
     margin: auto !important;
   }
 
   .mx-xxl-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
 
   .mx-xxl-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
 
   .mx-xxl-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
 
   .mx-xxl-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
 
   .mx-xxl-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
 
   .mx-xxl-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
 
   .mx-xxl-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
 
   .my-xxl-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
 
   .my-xxl-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
 
   .my-xxl-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
 
   .my-xxl-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
 
   .my-xxl-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
 
   .my-xxl-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
 
   .my-xxl-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
 
   .mt-xxl-0 {
     margin-top: 0 !important;
   }
 
   .mt-xxl-1 {
     margin-top: 0.25rem !important;
   }
 
   .mt-xxl-2 {
     margin-top: 0.5rem !important;
   }
 
   .mt-xxl-3 {
     margin-top: 1rem !important;
   }
 
   .mt-xxl-4 {
     margin-top: 1.5rem !important;
   }
 
   .mt-xxl-5 {
     margin-top: 3rem !important;
   }
 
   .mt-xxl-auto {
     margin-top: auto !important;
   }
 
   .me-xxl-0 {
     margin-right: 0 !important;
   }
 
   .me-xxl-1 {
     margin-right: 0.25rem !important;
   }
 
   .me-xxl-2 {
     margin-right: 0.5rem !important;
   }
 
   .me-xxl-3 {
     margin-right: 1rem !important;
   }
 
   .me-xxl-4 {
     margin-right: 1.5rem !important;
   }
 
   .me-xxl-5 {
     margin-right: 3rem !important;
   }
 
   .me-xxl-auto {
     margin-right: auto !important;
   }
 
   .mb-xxl-0 {
     margin-bottom: 0 !important;
   }
 
   .mb-xxl-1 {
     margin-bottom: 0.25rem !important;
   }
 
   .mb-xxl-2 {
     margin-bottom: 0.5rem !important;
   }
 
   .mb-xxl-3 {
     margin-bottom: 1rem !important;
   }
 
   .mb-xxl-4 {
     margin-bottom: 1.5rem !important;
   }
 
   .mb-xxl-5 {
     margin-bottom: 3rem !important;
   }
 
   .mb-xxl-auto {
     margin-bottom: auto !important;
   }
 
   .ms-xxl-0 {
     margin-left: 0 !important;
   }
 
   .ms-xxl-1 {
     margin-left: 0.25rem !important;
   }
 
   .ms-xxl-2 {
     margin-left: 0.5rem !important;
   }
 
   .ms-xxl-3 {
     margin-left: 1rem !important;
   }
 
   .ms-xxl-4 {
     margin-left: 1.5rem !important;
   }
 
   .ms-xxl-5 {
     margin-left: 3rem !important;
   }
 
   .ms-xxl-auto {
     margin-left: auto !important;
   }
 
   .p-xxl-0 {
     padding: 0 !important;
   }
 
   .p-xxl-1 {
     padding: 0.25rem !important;
   }
 
   .p-xxl-2 {
     padding: 0.5rem !important;
   }
 
   .p-xxl-3 {
     padding: 1rem !important;
   }
 
   .p-xxl-4 {
     padding: 1.5rem !important;
   }
 
   .p-xxl-5 {
     padding: 3rem !important;
   }
 
   .px-xxl-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
 
   .px-xxl-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
 
   .px-xxl-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
 
   .px-xxl-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
 
   .px-xxl-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
 
   .px-xxl-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
 
   .py-xxl-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
 
   .py-xxl-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
 
   .py-xxl-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
 
   .py-xxl-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
 
   .py-xxl-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
 
   .py-xxl-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
 
   .pt-xxl-0 {
     padding-top: 0 !important;
   }
 
   .pt-xxl-1 {
     padding-top: 0.25rem !important;
   }
 
   .pt-xxl-2 {
     padding-top: 0.5rem !important;
   }
 
   .pt-xxl-3 {
     padding-top: 1rem !important;
   }
 
   .pt-xxl-4 {
     padding-top: 1.5rem !important;
   }
 
   .pt-xxl-5 {
     padding-top: 3rem !important;
   }
 
   .pe-xxl-0 {
     padding-right: 0 !important;
   }
 
   .pe-xxl-1 {
     padding-right: 0.25rem !important;
   }
 
   .pe-xxl-2 {
     padding-right: 0.5rem !important;
   }
 
   .pe-xxl-3 {
     padding-right: 1rem !important;
   }
 
   .pe-xxl-4 {
     padding-right: 1.5rem !important;
   }
 
   .pe-xxl-5 {
     padding-right: 3rem !important;
   }
 
   .pb-xxl-0 {
     padding-bottom: 0 !important;
   }
 
   .pb-xxl-1 {
     padding-bottom: 0.25rem !important;
   }
 
   .pb-xxl-2 {
     padding-bottom: 0.5rem !important;
   }
 
   .pb-xxl-3 {
     padding-bottom: 1rem !important;
   }
 
   .pb-xxl-4 {
     padding-bottom: 1.5rem !important;
   }
 
   .pb-xxl-5 {
     padding-bottom: 3rem !important;
   }
 
   .ps-xxl-0 {
     padding-left: 0 !important;
   }
 
   .ps-xxl-1 {
     padding-left: 0.25rem !important;
   }
 
   .ps-xxl-2 {
     padding-left: 0.5rem !important;
   }
 
   .ps-xxl-3 {
     padding-left: 1rem !important;
   }
 
   .ps-xxl-4 {
     padding-left: 1.5rem !important;
   }
 
   .ps-xxl-5 {
     padding-left: 3rem !important;
   }
 }
 @media print {
   .d-print-inline {
     display: inline !important;
   }
 
   .d-print-inline-block {
     display: inline-block !important;
   }
 
   .d-print-block {
     display: block !important;
   }
 
   .d-print-grid {
     display: grid !important;
   }
 
   .d-print-table {
     display: table !important;
   }
 
   .d-print-table-row {
     display: table-row !important;
   }
 
   .d-print-table-cell {
     display: table-cell !important;
   }
 
   .d-print-flex {
     display: flex !important;
   }
 
   .d-print-inline-flex {
     display: inline-flex !important;
   }
 
   .d-print-none {
     display: none !important;
   }
 }