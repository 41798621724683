.ox-search-menu {
  padding: 90px 30px 0;
  position: relative;
  width: 100%;
}

.ox-search-menu__option-items {
  margin-top: 4rem;
  text-align: center;

  @include more-than(md) {
    margin-top: 6rem;
  }

  @include more-than(lg) {
    margin-top: 7rem;
  }

  @include more-than(xl) {
    margin-top: 8rem;
  }
}

.ox-search-menu__inner.ox-search-menu__inner-outer {
  margin-left: -3px;
  margin-right: -3px;
}

.ox-search-menu__desc,
.ox-search-menu__link,
.ox-search-menu__desc a,
.ox-search-menu__link a {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #fff;
  }
}

.ox-search-menu__link.ox-search-menu__link_no-upper,
.ox-search-menu__link.ox-search-menu__link_no-upper a {
  text-transform: none;
}

.ox-search-menu__desc a,
.ox-search-menu__link a {
  padding: 0;
}

.ox-search-menu__link-tag {
  font-size: 1rem;
  border: 1px solid #fff;
  padding: 4px 8px;
  margin: 5px 6px 5px 0;
}

.ox-search-menu__link,
.ox-search-menu__link a {
  cursor: pointer;
}

.ox-search-menu__link--select {
  cursor: auto;

  &::after {
    bottom: 100%;
  }

  &:hover::after {
    bottom: 100%;
    opacity: 1;
  }
}

.ox-search-menu__item {
  margin-bottom: 80px;
}

.ox-search-menu__item:last-of-type {
  margin-bottom: 0;
}

.ox-search-menu__option-values {
  display: flex !important;
  flex-direction: column;
}

.ox-search-menu__option-values.ox-search-menu__option-values-row {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
}

.ox-search-menu__option-label {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
}