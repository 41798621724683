footer {
    background-color: #fff;

    .footer-line {
        border-top: 1px solid #757575;
        width: 100%;
        margin: 0 auto;
    }

    .footer-col {
        /*
        padding: 20px 15px;
        */
        padding: 0 15px;

        h3, h4 {
            color: #757575;
            width: 100%;
            margin-bottom: 16px;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;

                a, span {
                    color: #757575;
                }

                a:hover {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
        
    }

    .row {
        padding: 80px 0;
    }

    .social-row {
        padding: 0;
        flex-direction: row;

        .social-site {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin-right: 16px;
            border: 1px solid #757575;
            border-radius: 50%;

            i {
                font-size: 16px;
                color: #757575;
            }
        }

        .social-site:hover i {
            color: #000;
            border-color: #000;
        }

        .social-site:last-child {
            margin-right: 0;
        }
    }
    
}

@media (min-width: 992px) {
    footer .row:not(.social-row) {
        /*
        padding: 130px 0 110px;
        */
        padding: 20px 0;
    }
}